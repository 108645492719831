import { css } from "@emotion/react"


const icon = css`
  [data-theme-icon] {
    width: 3rem;
    height: 3rem;
    display: inline-block;
  }

  [data-theme-icon] input[type="checkbox"] {
    display: none;
  }
`

export default icon
