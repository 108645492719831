const detectTheme = (): string => {
  const theme = localStorage.getItem('theme')

    if(theme) {
      return theme
    }
  
  return 'none'
}


export {
  detectTheme,
}
