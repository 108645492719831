interface Props {
  full?: boolean
}

const Section: FC<Props> = ({ children, full, ...rest }) => (
  <section data-section={ full ? "full" : "inline" } {...rest}>
    {children}
  </section>
)

export default Section
