/** @jsx jsx */
import { Link } from "gatsby"
import { jsx, css } from "@emotion/react"
import { useRef, useState } from "react"
import { detectTheme } from "ds/theme"
import { isSSR } from "utils/ssr"

const styles = css`
  &[data-navbar] {
    top: 0;
    z-index: 4;
    position: sticky;
    font-family: Inconsolata;
  }

  [data-theme-toggle],
  [data-menu-button-container] {
    transition: color 0.2s, background-color 0.2s;
  }

  [data-menu-button] + label {
    display: flex;
    margin-top: 0.40rem;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    box-sizing: border-box;
    margin-right: 0.5rem;
    border-radius: 4px;
  }

  [data-menu-button] {
    display: none;
  }

  [data-navbar-controls] {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  [data-brand] {
    flex-grow: 1;
  }

  [data-brand-name] {
    margin-right: 0.5rem;
  }

  [data-theme-toggle] {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    margin-top: 0.20rem;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 4px;
  }

  [data-light-theme-name],
  [data-dark-theme-name] {
    margin-right: 0.5rem;
    width: 4rem;
  }
`

const Navbar: FC = () => {
  const bodyRef = useRef<HTMLElement|null>(null)
  const htmlRef = useRef<HTMLElement|null>(null)
  const [toggleThemeChecked, setToggleThemeChecked] = useState(!isSSR() ? detectTheme() === 'dark' : false)

  const getBodyElement = () => {
    const body = document.body
    bodyRef.current = body
    const html = document.documentElement
    htmlRef.current = html
    const detectedTheme = detectTheme()
    if(['dark', 'light'].includes(detectedTheme)) {
      bodyRef.current.setAttribute('data-prefers', detectedTheme)
      htmlRef.current.setAttribute('data-prefers', detectedTheme)
    }
  }

  const handleOpenSidebar = () => {
    if(bodyRef.current) {
      bodyRef.current.setAttribute('data-sidebar', "open")
    }
  }

  const handleToggleTheme = () => {
    if(bodyRef.current && htmlRef.current) {
      const isDarkMode =
        bodyRef.current.getAttribute('data-prefers') === 'dark' &&
        htmlRef.current.getAttribute('data-prefers') === 'dark'
      const isLightMode =
        bodyRef.current.getAttribute('data-prefers') === 'light' &&
        htmlRef.current.getAttribute('data-prefers') === 'light'
      
      if (isDarkMode) {
        bodyRef.current.setAttribute('data-prefers', 'light')
        htmlRef.current.setAttribute('data-prefers', 'light')
        setToggleThemeChecked(false)
        localStorage.setItem('theme', 'light')
        return
      }
    
      if (isLightMode) {
        bodyRef.current.setAttribute('data-prefers', 'dark')
        htmlRef.current.setAttribute('data-prefers', 'dark')
        setToggleThemeChecked(true)
        localStorage.setItem('theme', 'dark')
        return
      }

      const { matches: prefersDarkMode } = window.matchMedia('(prefers-color-scheme: dark)')
      
      if(prefersDarkMode) {
        bodyRef.current.setAttribute('data-prefers', 'light')
        htmlRef.current.setAttribute('data-prefers', 'light')
        setToggleThemeChecked(false)
        localStorage.setItem('theme', 'light')
        return
      }

      bodyRef.current.setAttribute('data-prefers', 'dark')
      htmlRef.current.setAttribute('data-prefers', 'dark')
      setToggleThemeChecked(true)
      localStorage.setItem('theme', 'dark')
    }
  }

  return (
    <nav css={styles} data-navbar ref={getBodyElement}>
      <div data-navbar-controls data-section="inline">
        <input id="menu-open-button" type="button" data-menu-button="open" onClick={handleOpenSidebar} />
        <label data-menu-button-container htmlFor="menu-open-button">
          <svg data-menu-icon-open width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5" d="M4 15.5C4 15.22 4.22 15 4.5 15H19.5C19.78 15 20 15.22 20 15.5C20 15.78 19.78 16 19.5 16H4.5C4.22 16 4 15.78 4 15.5Z"></path>
            <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5" d="M4 8.5C4 8.22 4.22 8 4.5 8H19.5C19.78 8 20 8.22 20 8.5C20 8.78 19.78 9 19.5 9H4.5C4.22 9 4 8.78 4 8.5Z"></path>
          </svg>
        </label>
        <span data-brand>
          <Link data-brand-name to="/">Blogfi Notes</Link>
          <span>☕️</span>
        </span>
        <span data-light-theme-name>Latte</span>
        <span data-dark-theme-name>Espresso</span>
        <label data-theme-toggle={toggleThemeChecked ? 'dark' : 'light'}>
          <input data-theme-toggle-button type="checkbox" name="theme" onChange={handleToggleTheme} checked={toggleThemeChecked} />
          <svg data-light-theme-icon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" fill="none" d="M16 18H8C4.69 18 2 15.31 2 12C2 8.69 4.69 6 8 6H16C19.31 6 22 8.69 22 12C22 15.31 19.31 18 16 18Z"></path>
            <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" fill="none" d="M8 14.5C9.38071 14.5 10.5 13.3807 10.5 12C10.5 10.6193 9.38071 9.5 8 9.5C6.61929 9.5 5.5 10.6193 5.5 12C5.5 13.3807 6.61929 14.5 8 14.5Z"></path>
          </svg>
          <svg data-dark-theme-icon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" fill="none" d="M8 18H16C19.31 18 22 15.31 22 12C22 8.69 19.31 6 16 6H8C4.69 6 2 8.69 2 12C2 15.31 4.69 18 8 18Z"></path>
            <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1" fill="none" d="M16 14.5C14.6193 14.5 13.5 13.3807 13.5 12C13.5 10.6193 14.6193 9.5 16 9.5C17.3807 9.5 18.5 10.6193 18.5 12C18.5 13.3807 17.3807 14.5 16 14.5Z"></path>
          </svg>
        </label>
      </div>
  </nav>
  )
}


export default Navbar
