import { css } from "@emotion/react"

const norm = css`
  :root {
    --overflow: visible;
  } 

  html, body {
    margin: 0;
    scroll-behavior: smooth;
  }

  ul > li,
  ol > li {
    padding-bottom: 1rem;
  }

  ul > li:last-child,
  ol > li:last-child {
    padding-bottom: 0;
  }

  select {
    border: 1px solid;
    border-radius: 2px;
    background-color: transparent;
  }

  body {
    overflow: var(--overflow);
  }
`

export default norm
