import { css } from "@emotion/react"

import NotoBold from "./fonts/Noto/Bold.otf"
import NotoLight from "./fonts/Noto/Light.otf"
import NotoRegular from "./fonts/Noto/Regular.otf"

import InconsolataBold from "./fonts/Inconsolata/Regular.ttf"
import InconsolataLight from "./fonts/Inconsolata/Regular.ttf"
import InconsolataRegular from "./fonts/Inconsolata/Regular.ttf"

const typeface = css`
  @font-face {
    font-family: Inconsolata;
    src: url('${InconsolataBold}') format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: Inconsolata;
    src: url('${InconsolataLight}') format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: Inconsolata;
    src: url('${InconsolataRegular}') format('truetype');
  }

  @font-face {
    font-family: Noto;
    src: url('${NotoBold}') format('opentype');
    font-weight: bold;
  }

  @font-face {
    font-family: Noto;
    src: url('${NotoLight}') format('opentype');
    font-weight: 300;
  }

  @font-face {
    font-family: Noto;
    src: url('${NotoRegular}') format('opentype');
  }

  html, body {
    font-family: Noto;
  }
`


export default typeface
