import { css } from "@emotion/react"


const palette = css`
:root {
  --light: #FFF8E7;
  --dark: #3b2f2f;

  --white: #f5f5f5;
  --black: #1a1a1a;

  --accent-dark: #35BCF3;
  --accent-light: #3947E0;
}

html,
body,
[data-navbar] {
  transition: background-color 0.2s, color 0.2s;
}

@media screen and (prefers-color-scheme: light) {
  html, body {
    color: var(--dark);
    background-color: var(--light);
  }

  * {
    border-color: var(--dark);
  }

  a {
    color: var(--dark);
  }

  svg {
    stroke: var(--dark);
  }

  [data-dark-theme-icon] {
    display: none;
  }

  [data-navbar],
  [data-sidebar] {
    background-color: var(--light);
  }

  [data-dark-theme-name] {
    display: none;
  }

  [data-card] {
    background-color: var(--light);
  }

  select {
    border-color: var(--dark);
  }

  [data-theme-toggle]:hover {
    border: 1px solid var(--dark);
  }
  
  [data-menu-button-container]:hover {
    border: 1px solid var(--dark);
  }
}

@media screen and (prefers-color-scheme: dark) {
  html, body {
    color: var(--light);
    background-color: var(--dark);
  }

  * {
    border-color: var(--light);
  }

  a {
    color: var(--light);
  }

  svg {
    stroke: var(--light);
  }

  [data-light-theme-icon] {
    display: none;
  }

  [data-navbar],
  [data-sidebar] {
    background-color: var(--dark);
  }

  [data-light-theme-name] {
    display: none;
  }

  [data-card] {
    background-color: var(--dark);
  }

  select {
    color: var(--light);
    border-color: var(--light);
  }

  [data-theme-toggle]:hover {
    border: 1px solid var(--light);
  }
  
  [data-menu-button-container]:hover {
    border: 1px solid var(--light);
  }
}


[data-prefers="dark"] {
  color: var(--light);
  background-color: var(--dark);
}

[data-prefers="light"] {
  color: var(--dark);
  background-color: var(--light);
}

[data-prefers="light"] a {
  color: var(--dark);
}

[data-prefers="light"] svg {
  stroke: var(--dark);
}

[data-prefers="dark"] a {
  color: var(--light);
}

[data-prefers="dark"] svg {
  stroke: var(--light);
}

[data-theme-toggle] [data-theme-toggle-button] {
  display: none;
}

[data-prefers="light"] [data-dark-theme-icon],
[data-prefers="light"] [data-dark-theme-name] {
  display: none;
}

[data-prefers="dark"] [data-light-theme-icon],
[data-prefers="dark"] [data-light-theme-name] {
  display: none;
}

[data-prefers="dark"] [data-dark-theme-icon],
[data-prefers="dark"] [data-dark-theme-name] {
  display: inline-block;
}

[data-prefers="light"] [data-light-theme-icon],
[data-prefers="light"] [data-light-theme-name] {
  display: inline-block;
}

[data-prefers="light"] * {
  border-color: var(--dark);
}

[data-prefers="dark"] * {
  border-color: var(--light);
}

[data-prefers="dark"] [data-navbar],
[data-prefers="dark"] [data-sidebar],
[data-prefers="dark"] [data-card] {
  background-color: var(--dark);
}

[data-prefers="light"] [data-navbar],
[data-prefers="light"] [data-sidebar],
[data-prefers="light"] [data-card] {
  background-color: var(--light);
}

[data-prefers="light"] select {
  color: var(--dark);
  border-color: var(--dark);
}

[data-prefers="dark"] select {
  color: var(--light);
  border-color: var(--light);
}

[data-theme-toggle="light"]:hover {
  border: 1px solid var(--dark);
}

[data-theme-toggle="dark"]:hover {
  border: 1px solid var(--light);
}

[data-prefers="light"] [data-theme-toggle]:hover,
[data-prefers="light"] [data-menu-button-container]:hover {
  border: 1px solid var(--dark);
  background-color: var(--dark);
}

[data-prefers="dark"] [data-theme-toggle]:hover,
[data-prefers="dark"] [data-menu-button-container]:hover {
  border: 1px solid var(--light);
  background-color: var(--light);
}

[data-prefers="dark"] [data-theme-toggle]:hover svg,
[data-prefers="dark"] [data-menu-button-container]:hover svg {
  stroke: var(--dark);
}

[data-prefers="light"] [data-theme-toggle]:hover svg,
[data-prefers="light"] [data-menu-button-container]:hover svg {
  stroke: var(--light);
}
`

export default palette
