/** @jsx jsx */
import { jsx, css, Global } from "@emotion/react"
import { useRef } from "react"


const global = css`
  :root {
    --overflow: visible;
  } 
  body[data-sidebar="open"] {
    overflow: hidden;
  }

  body[data-sidebar="closed"] aside[data-sidebar] {
    width: 0;
  }
  
  body[data-sidebar="open"] aside[data-sidebar] {
    width: 100vw;
  }
`

const styles = css`
  &[data-sidebar] {
    top: 0;
    z-index: 5;
    height: 100%;
    position: fixed;
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow: hidden;
    transition: width 0.2s;
  }

  [data-menu-button-container] {
    transition: color 0.2s, background-color 0.2s;
  }

  [data-menu-button] {
    display: none;
  }

  [data-sidebar-links] {
    margin-top: 1rem;
    padding-left: 0.5rem;
    list-style-type: none;
  }

  [data-menu-button] + label {
    display: flex;
    margin-top: 0.40rem;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    box-sizing: border-box;
    margin-right: 0.5rem;
    border-radius: 4px;
  }
`



const Sidebar: FC = () => {
  const ref = useRef<HTMLElement|null>(null)

  const getBodyElement = () => {
    const body = document.body
    ref.current = body
  }

  const handleCloseSidebar = () => {
    if(ref.current) {
      ref.current.setAttribute('data-sidebar', "closed")
    }
  }


  return (
    <aside css={styles} data-sidebar ref={getBodyElement}>
      <Global styles={global} />
      <div data-section="inline">
        <input id="menu-close-button" type="button" data-menu-button="close" onClick={handleCloseSidebar} />
        <label data-menu-button-container htmlFor="menu-close-button">
        <svg data-menu-icon-close width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5" d="M2 2L22 22"></path>
          <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5" d="M22 2L2 22"></path>
        </svg>
        </label>
        <ul data-sidebar-links>
          {/* <li>
            <a href="#">FAQ</a>
          </li> */}
          <li>
            <a target="_blank" href="https://evictorio.dev" rel="noreferrer">About the author</a>
          </li>
        </ul>
      </div>
    </aside>
  )
}

export default Sidebar
