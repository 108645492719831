import Helmet from "react-helmet"
import FaviconImg from "./favicon.png"

const Favicon: FC = () => (
  <Helmet>
    <link rel="shortcut icon" href={FaviconImg as string} type="image/x-icon" />
  </Helmet>
)

export default Favicon
