import Metatags from "seo/Metatags"
import OpenGraph from "seo/OpenGraph"
import TwitterMetas from "seo/TwitterMetas"
import Favicon from "seo/Favicon"
import DesignSystem from "ds"
import Navbar from "components/Navbar"
import Sidebar from "components/Sidebar"
import Footer from "components/Footer"

const Base: FC = ({ children }) => (
  <DesignSystem>
    <Favicon />
    <Navbar />
    <Sidebar />
    {children}
    <Footer />
  </DesignSystem>
)

Base.displayName = "Base"

export default Base
