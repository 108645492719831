import { Global, css } from "@emotion/react"
import { Fragment } from "react"
import flow from "./flow"
import norm from "./norm"
import palette from "./palette"
import typeface from "./typeface"
import icon from "./icon"

const styles = css`
  ${typeface};
  ${palette};
  ${flow};
  ${norm};
  ${icon};
`



const DesignSystem: FC = ({ children }) => (
  <Fragment>
    <Global styles={styles}/>
    {children}
  </Fragment>
)


export default DesignSystem
