import { css } from "@emotion/react"

const flow = css`
  [data-section*='inline'] {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @media screen and (min-width: 48rem) {
    p {
      max-width: 50rem;
    }
  }

  @media screen and (min-width: 80rem) {
    [data-section*="inline"] {
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export default flow
