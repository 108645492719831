/** @jsx jsx */
import { jsx, css } from "@emotion/react"

const styles = css`
  [data-footer-wrapper] {
    height: 10rem;
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    font-family: Inconsolata;
  }
`

const Footer: FC = () => (
  <footer css={styles} data-footer>
    <div data-footer-wrapper data-section="inline">
      Made with ❤️ by <a target="_blank" href="https://twitter.com/evictorio92" rel="noreferrer">@evictorio92</a>
    </div>
  </footer>
)

export default Footer
